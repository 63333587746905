import React from "react";
import { Route, Switch } from "react-router-dom";

import Homepage from "components/Homepage/Homepage";
import Graphic from "components/Graphic/Graphic";
import AdminPage from "components/AdminPage/AdminPage";
import Architecture from "components/Architecture/Architecture";
import ArchItem from "components/ArchItem/ArchItem";

const AppRoutes = () => {
  return (
      <Switch>
        <Route exact path="/architecture" component={Architecture} />
        <Route exact path="/architecture/:ArchId" component={ArchItem} />
        <Route exact path="/graphic-design" component={Graphic} />
        <Route exact path="/lyovik" component={AdminPage} />
        <Route path="*" component={Homepage} />
      </Switch>
  );
};

export default AppRoutes;
