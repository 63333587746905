const firebaseConfig = {
  apiKey: "AIzaSyBIs2aEdZ9y8NRGkDEKb0WZXbD6lEwjbhk",
  authDomain: "portfolio-c8be3.firebaseapp.com",
  databaseURL: "https://portfolio-c8be3-default-rtdb.firebaseio.com",
  projectId: "portfolio-c8be3",
  storageBucket: "portfolio-c8be3.appspot.com",
  messagingSenderId: "215143761597",
  appId: "1:215143761597:web:33b5c7bc15a93cf28719d4",
  measurementId: "G-X210VPPX71"
};
export default firebaseConfig;
