import React, { useState, useEffect } from "react";

import fbService from "api/fbService";
import picturesMockup from "data-mockup/pictures";

import Image from "components/Image/Image";

import "./AdminPage.scss";

const AdminPage = () => {
  //inputs state
  const [state, setState] = useState({
    descVal: "",
    graphicVal: "",
    archVal: "",
    gSwap1: "",
    gSwap2: "",
    aSwap1: "",
    aSwap2: "",
  });

  const { descVal, graphicVal, archVal, gSwap1, gSwap2, aSwap1, aSwap2 } =
    state;

  //picture arrays
  const [graphic, setGraphic] = useState([]);
  const [architecture, setArchitecture] = useState([]);

  //url regex
  const regex =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gm;

  useEffect(() => {
    setPictures();
  }, []);

  const inputChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const setPictures = async () => {
    const resGraph = await fbService.getAllItems("graphic");
    const resArch = await fbService.getAllItems("architecture");
    setGraphic(resGraph);
    setArchitecture(resArch);
  };

  const pictureAdder = (path) => {
    if (path === "graphic") {
      if (graphicVal.match(regex)) {
        fbService.addItem("graphic", {
          link: graphicVal,
        });
        setState({ ...state, graphicVal: "" });
      } else alert("Wrong url");
    } else if (path === "architecture") {
      if (archVal.match(regex)) {
        fbService.addItem("architecture", {
          subPictures: picturesMockup,
          link: archVal,
          description: descVal,
        });
        setState({ ...state, archVal: "" });
      } else alert("Wrong url");
    }
    setPictures();
    return;
  };

  const subPictureAdder = (id) => {
    if (archVal.match(regex)) {
      fbService.addSubItem(`architecture/${id}`, {
        link: archVal,
      });
      setState({ ...state, archVal: "" });
      setPictures();
      return;
    }
    alert("Wrong url");
  };

  const pictureEditor = (id, path) => {
    if (path === "graphic") {
      if (graphicVal.match(regex)) {
        fbService.editItem(path, { id: id, link: graphicVal });
        setState({ ...state, graphicVal: "" });
      } else alert("Wrong url");
    } else if (path === "architecture") {
      if (!archVal.match(regex) && descVal === "") {
        alert("Wrong input");
        return;
      }
      if (archVal.match(regex)) {
        if (descVal === "") {
          fbService.editItem(path, { id: id, link: archVal });
        } else {
          fbService.editItem(path, {
            id: id,
            link: archVal,
            description: descVal,
          });
        }
      } else {
        fbService.editItem(path, { id: id, description: descVal });
      }
      setState({ ...state, archVal: "", descVal: "" });
    }
    setPictures();
    return;
  };

  const subPictureEditor = (id, subId, path) => {
    if (archVal.match(regex)) {
      fbService.editSubItem(path, id, { id: subId, link: archVal });
    } else alert("Wrong url");
    setPictures();
    return;
  };

  const pictureDeleter = (id, path) => {
    fbService.deleteItem(id, path);
    setPictures();
  };

  const subPictureDeleter = (id, innerId) => {
    fbService.deleteSubItem(id, innerId);
    setPictures();
  };

  const pictureSwapper = (path) => {
    let swap1, swap2;

    if (path === "graphic") {
      swap1 = gSwap1 - 1;
      swap2 = gSwap2 - 1;

      if (
        swap1 < 0 ||
        swap2 < 0 ||
        swap1 > graphic.length ||
        swap2 > graphic.length ||
        swap1 === swap2
      ) {
        alert("Wrong input in swap fields");
        return;
      }

      let tempArr = graphic;
      let tempPic = { ...tempArr[swap1], id: tempArr[swap2].id };
      tempArr[swap1] = { ...tempArr[swap2], id: tempArr[swap1].id };
      tempArr[swap2] = tempPic;
      setGraphic(tempArr);
      fbService.setItems("graphic", tempArr);
      setState({ ...state, gSwap1: "", gSwap2: "" });
    } else if (path === "architecture") {
      swap1 = aSwap1 - 1;
      swap2 = aSwap2 - 1;

      if (
        swap1 < 0 ||
        swap2 < 0 ||
        swap1 > graphic.length ||
        swap2 > graphic.length ||
        swap1 === swap2
      ) {
        alert("Wrong input in swap fields");
        return;
      }

      let tempArr = architecture;
      let tempPic = { ...tempArr[swap1], id: tempArr[swap2].id };
      tempArr[swap1] = { ...tempArr[swap2], id: tempArr[swap1].id };
      tempArr[swap2] = tempPic;
      setArchitecture(tempArr);
      fbService.setItems("architecture", tempArr);
      setState({ ...state, aSwap1: "", aSwap2: "" });
    }
  };
  return (
    <div className="app-admin">
      <div className="app-admin__adder">
        <div className="app-admin__adder__main">
          <div className="app-admin__adder__item">
            <button className="app-admin__adder__button__active">
              Graphic
            </button>
            <input
              name="graphicVal"
              className="app-admin__adder__input"
              value={graphicVal}
              onChange={(e) => inputChangeHandler(e)}
              placeholder="Enter url"
            ></input>
            <button
              className="app-admin__adder__button"
              onClick={() => pictureAdder("graphic")}
            >
              Add to graphic
            </button>
          </div>
          <div className="app-admin__adder__item">
            <button className="app-admin__adder__button__active">
              Architecture
            </button>
            <input
              name="archVal"
              className="app-admin__adder__input"
              value={archVal}
              onChange={(e) => inputChangeHandler(e)}
              placeholder="Enter url"
            ></input>
            <input
              name="descVal"
              className="app-admin__adder__input"
              value={descVal}
              onChange={(e) => inputChangeHandler(e)}
              placeholder="Enter name"
            ></input>
            <button
              className="app-admin__adder__button"
              onClick={() => pictureAdder("architecture")}
            >
              Add to architecture
            </button>
          </div>
        </div>
        <div className="app-admin__adder__swapper">
          <div className="app-admin__adder__swapper__item">
            <input
              placeholder="First number"
              name="gSwap1"
              value={gSwap1}
              onChange={(e) => inputChangeHandler(e)}
            />
            <input
              placeholder="Second number"
              name="gSwap2"
              value={gSwap2}
              onChange={(e) => inputChangeHandler(e)}
            />
            <button onClick={() => pictureSwapper("graphic")}>Swap</button>
          </div>
          <div className="app-admin__adder__swapper__item">
            <input
              placeholder="First number"
              name="aSwap1"
              value={aSwap1}
              onChange={(e) => inputChangeHandler(e)}
            />
            <input
              placeholder="Second number"
              name="aSwap2"
              value={aSwap2}
              onChange={(e) => inputChangeHandler(e)}
            />
            <button onClick={() => pictureSwapper("architecture")}>Swap</button>
          </div>
        </div>
      </div>
      <div className="app-admin__viewer">
        <div className="app-admin__viewer__graphic">
          {graphic.length > 0 ? (
            graphic.map((el, idx) => {
              return (
                <div key={idx} className="app-admin__viewer__graphic__item">
                  <span>{idx + 1}</span>
                  <Image
                    link={el.link}
                    className="app-admin__viewer__graphic__item__image"
                  />
                  <button
                    className="app-admin__viewer__graphic__item__button"
                    onClick={() => pictureEditor(el.id, "graphic")}
                  >
                    /
                  </button>
                  <button
                    className="app-admin__viewer__graphic__item__button"
                    onClick={() => pictureDeleter(el.id, "graphic")}
                  >
                    X
                  </button>
                </div>
              );
            })
          ) : (
            <div>Loading</div>
          )}
        </div>
        <div className="app-admin__viewer__architecture">
          {architecture.length > 0 ? (
            architecture.map((el, idx) => {
              return (
                <div
                  className="app-admin__viewer__architecture__item"
                  key={idx}
                >
                  <div className="app-admin__viewer__architecture__item__main">
                    <span>{idx + 1}</span>
                    <Image
                      link={el.link}
                      className="app-admin__viewer__architecture__item__image"
                    />
                    <span>{el.description}</span>
                    <button
                      className="app-admin__viewer__architecture__item__button"
                      onClick={() => subPictureAdder(el.id)}
                    >
                      +
                    </button>
                    <button
                      className="app-admin__viewer__graphic__item__button"
                      onClick={() => pictureEditor(el.id, "architecture")}
                    >
                      /
                    </button>
                    <button
                      className="app-admin__viewer__architecture__item__button"
                      onClick={() => pictureDeleter(el.id, "architecture")}
                    >
                      X
                    </button>
                  </div>
                  <div className="app-admin__viewer__architecture__item__subitems">
                    {!!Object.keys(el.subPictures) ? (
                      Object.keys(el.subPictures).map((elem, idx) => {
                        return (
                          <div
                            key={idx}
                            className="app-admin__viewer__architecture__item__subitems__item"
                          >
                            <span>{idx + 1}</span>
                            <Image
                              link={el.subPictures[elem].link}
                              className="app-admin__viewer__architecture__item__image"
                            />
                            <button
                              className="app-admin__viewer__graphic__item__button"
                              onClick={() =>
                                subPictureEditor(
                                  el.id,
                                  el.subPictures[elem].id,
                                  "architecture"
                                )
                              }
                            >
                              /
                            </button>
                            <button
                              className="app-admin__viewer__architecture__item__button"
                              onClick={() => subPictureDeleter(el.id, elem)}
                            >
                              X
                            </button>
                          </div>
                        );
                      })
                    ) : (
                      <div>Loading</div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div>Loading</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
