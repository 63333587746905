import React, { useEffect, useState } from "react";
import fbService from "api/fbService";

import Footer from "components/Footer/Footer";
import ArrowLink from "components/ArrowLink/ArrowLink";
import Loader from "components/Loader/Loader"

import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./Graphic.scss";

const Graphic = (props) => {
  const [pics, setPics] = useState([]);

  const getPictures = async (path) => {
    const pics = await fbService.getAllItems(path);
    setPics(pics);
  };

  useEffect(() => {
    getPictures("graphic");
  }, []);

  return (
    <>
      <div className="app-graphic">
        <div className="app-graphic__content">
          <ArrowLink
            history={props.history}
            to="architecture"
            arrow="<"
          ></ArrowLink>
            {pics.length !== 0 ? (
          <div className="app-graphic__content__items">
              <ul>
                {pics.map((el, idx) => {
                  return (
                    <li>
                      <img key={idx} src={el.link} alt={idx} />
                    </li>
                  );
                })}

                <li></li>
              </ul>
              </div>
            ) : (
              <div className='app-graphic__loader'><Loader/></div>
            )}
          <ArrowLink></ArrowLink>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Graphic;
