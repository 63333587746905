import React from "react";

import logo from "assets/Logo.png";

import "./Loader.scss";

const Loader = () => {
  return <img className="app-loader" alt="Loading" src={logo}></img>;
};

export default Loader;
