import React from "react";

import "./ArrowLink.scss";

const ArrowLink = (props) => {
  const pageChanger = (to) => {
    props.history.push("/" + to);
  };

  return (
    <div
      className={
        props.to ? "app-arrow-link" + " arrow-hover" : "app-arrow-link"
      }
      style={{height: props.height + 'px'}}
      onClick={props.to ? () => pageChanger(props.to) : () => {}}
    >
      {props.arrow}
    </div>
  );
};

export default ArrowLink;
