import firebase from "firebase/app";
import firebaseConfig from "./firebaseConfig";
import "firebase/database";

class fbService {
  constructor() {
    this.baseUrl = "https://portfolio-c8be3-default-rtdb.firebaseio.com";
    if (firebase.apps.length === 0) firebase.initializeApp(firebaseConfig);
  }

  setItems = async (path, mockUp) => {
    firebase.database().ref(`/${path}`).set(mockUp);
  };

  getAllItems = async (path) => {
    const res = await firebase.database().ref(`/${path}`).get();
    const data = res.toJSON();
    return Object.values(data);
  };

  getItem = async (path, id) => {
    const res = await firebase.database().ref(`/${path}/${id}`).get();
    const data = res.toJSON();
    return data;
  };

  addItem = async (path, item) => {
    const res = await firebase
      .database()
      .ref(path)
      .orderByKey()
      .limitToLast(1)
      .get();

    if (res.toJSON() === null) {
      const newItem = {
        ...item,
        id: 0,
      };

      await firebase.database().ref(`${path}/0`).set(newItem);
      return;
    }

    const lastItemJson = res.toJSON();
    const lastItem = Object.values(lastItemJson)[0];
    const { id } = lastItem;
    const newItem = {
      ...item,
      id: id + 1,
    };
    await firebase
      .database()
      .ref(`${path}/${id + 1}`)
      .set(newItem);
  };

  addSubItem = async (Id, item) => {
    const res = await firebase
      .database()
      .ref(`${Id}/subPictures`)
      .orderByKey()
      .limitToLast(1)
      .get();

    if (res.toJSON() === null) {
      const newItem = {
        ...item,
        id: 0,
      };

      await firebase.database().ref(`${Id}/subPictures/0`).set(newItem);
      return;
    }

    const lastItemJson = res.toJSON();
    const lastItem = Object.values(lastItemJson)[0];
    const { id } = lastItem;
    const newItem = {
      ...item,
      id: id + 1,
    };
    await firebase
      .database()
      .ref(`${Id}/subPictures/${id + 1}`)
      .set(newItem);
  };

  editItem = async (path, item) => {
    firebase.database().ref(`${path}/${item.id}`).update(item);
  };

  editSubItem = async (path, id, item) => {
    firebase
      .database()
      .ref(`${path}/${id}/subPictures/${item.id}`)
      .update(item);
  };

  deleteItem = async (id, path) => {
    const itemRef = firebase.database().ref(`${path}/${id}`);
    await itemRef.remove();
    const items = await this.getAllItems(path);
    firebase
      .database()
      .ref(path)
      .set(
        items.map((el, idx) => {
          return {
            ...el,
            id: idx,
          };
        })
      );
  };

  deleteSubItem = async (id, innerId) => {
    const itemRef = firebase
      .database()
      .ref(`architecture/${id}/subPictures/${innerId}`);
    await itemRef.remove();
    const items = await this.getAllItems(`architecture/${id}/subPictures`);
    firebase
      .database()
      .ref(`architecture/${id}/subPictures`)
      .set(
        items.map((el, idx) => {
          return {
            ...el,
            id: idx,
          };
        })
      );
  };
}

const fbservice = new fbService();
export default fbservice;
