import React, { useEffect, useState } from "react";
import fbService from "api/fbService";

import ArrowLink from "components/ArrowLink/ArrowLink";
import Footer from "components/Footer/Footer";
import Link from "components/Link/Link";

import "./Architecture.scss";
import Loader from "components/Loader/Loader";

const Architecture = (props) => {
  const [pics, setPics] = useState([]);
  const [linkHeight, setLinkHeight] = useState(250);

  const getPictures = async (path) => {
    const pics = await fbService.getAllItems(path);
    setPics(pics);
  };

  const heightAdjuster = () => {
    let h = document.getElementsByClassName(
      "app-architecture__content__items"
    )[0].clientHeight;
    setLinkHeight(h);
  };

  useEffect(() => {
    getPictures("architecture");
    setTimeout(() => {
      heightAdjuster();
    }, 1000);
  }, []);

  return (
    <div className="app-architecture">
      <div className="app-architecture__content">
        <ArrowLink height={linkHeight}></ArrowLink>
        <div className="app-architecture__content__items">
          {pics.length !== 0 ? (
            pics.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className="app-architecture__content__items__item"
                >
                  <Link to={`architecture/${el.id}`}>
                    <img src={el.link} alt={el.description} />
                  </Link>
                </div>
              );
            })
          ) : (
            <div className='app-architecture__loader'><Loader/></div>
          )}
        </div>
        <ArrowLink
          history={props.history}
          to="graphic-design"
          height={linkHeight}
          arrow=">"
        ></ArrowLink>
      </div>
      <Footer />
    </div>
  );
};

export default Architecture;
