import React, { useEffect, useState } from "react";

import fbService from "api/fbService";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ArchItem.scss";

const ArchItem = (props) => {
  const [item, setItem] = useState({});

  const getItem = () => {
    fbService
      .getItem("architecture", props.match.params.ArchId)
      .then((data) => {
        if (data === null) {
          props.history.push("/architecture");
        }
        setItem(data);
      })
      .catch(() => {
        props.history.push("/architecture");
      });
  };
  useEffect(() => {
    getItem();
  }, []);

  return (
    <>
      {item ? (
        <div className="app-arch-item">
          <div className="app-arch-item__header">
            <span>{item.description}</span>
          </div>
          <div className="app-arch-item__content">
            {item.subPictures ? (
              Object.keys(item.subPictures).map((el, idx) => {
                return (
                  <img src={item.subPictures[el].link} alt={idx} key={idx} />
                );
              })
            ) : (
              <div> Loading</div>
            )}
            {console.log("subpics", item.subPictures)}
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};

export default ArchItem;
