import React from "react";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "components/AppRoutes/AppRoutes";
import Layout from "components/Layout/Layout";

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <AppRoutes/>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
