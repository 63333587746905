import React from "react";

import Logo from "assets/background.mp4";
import Footer from "components/Footer/Footer";

import "./Homepage.scss";

const Homepage = (props) => {
  const pageChanger = (to) => {
    props.history.push("/" + to);
  };

  return (
    <>
      <div className="app-homepage">
        <div className="app-homepage__landing-container">
          <video autoPlay muted loop className="app-homepage__logo">
            <source src={Logo} type="video/mp4"></source>
          </video>
        </div>
        <div className="app-homepage__links">
          <div className="app-homepage__links__wrapper">
            <div className="app-homepage__links__wrapper__inner">
              <div
                onClick={() => pageChanger("architecture")}
                className="app-homepage__links__architecture"
              ></div>
            </div>
            <div className="app-homepage__links__wrapper__inner">
              <div
                onClick={() => pageChanger("graphic-design")}
                className="app-homepage__links__graphic"
              ></div>
            </div>
          </div>
        </div>
        <div className="app-homepage__footer"> 

        <Footer/>
        </div>
      </div>
    </>
  );
};

export default Homepage;
