import React, { useState } from "react";

import Icon from "components/Icon/Icon";

import downloadLogo from "assets/icons/download.svg";
import linkedInLogo from "assets/icons/linkedin.svg";
import instagramLogo from "assets/icons/instagram.svg";
import emailLogo from "assets/icons/email.svg";
import checkLogo from "assets/icons/check.svg";
import footerImage from "assets/FooterImage.jpeg";
import cv from "assets/Levon Abrahamyan CV.pdf"

import "./Footer.scss";

const Footer = () => {
  const [copy, setCopy] = useState(emailLogo);

  const emailCopy = () => {
    navigator.clipboard.writeText("levon.abrahamyan@hotmail.com");
    setCopy(checkLogo);
    setTimeout(() => setCopy(emailLogo), 1500);
  };

  return (
    <div className="app-footer">
      <div className="app-footer__about-me">
        <img
          className="app-footer__about-me__image"
          src={footerImage}
          alt="Levon Abrahamyan"
          width="100"
        />
        <div className="app-footer__about-me__text">
          Hello, I am Levon Abrahamyan, and I am currently doing master’s in
          architecture. If one asks me to describe myself in one word, the
          answer would be “impatient”. This word is often seen rather as a
          disadvantage, but I perceive a lot of positivity in it. Being
          impatient pushed me leaving my country at the age of 16 to pursue my
          dream of becoming an architect. It motivated me to learn Dutch in one
          year and to study at KU Leuven, one of the best universities in
          Belgium. Impatience gave me the privilege of not waiting for the
          teachers to teach me architectural software but becoming competent in
          them beforehand. It also held me back from the evil thoughts of giving
          up during the difficult times of my life. I learned how not to wait,
          but act. Impatience is my motivation, my source of energy, my key to
          success.
        </div>
      </div>
      <div className="app-footer__icons">
        <a
          className="app-footer__icons__item"
          href="https://www.instagram.com/xwhitecrossx/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon at={instagramLogo} alt="visit Instagram" />
        </a>
        <a
          href="https://www.linkedin.com/in/levon-abrahamyan-b15146222/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon at={linkedInLogo} alt="visit linkedIn" />
        </a>
        <Icon at={copy} alt="copy email" onClick={() => emailCopy()} />
        <a href={cv} download>
          <Icon at={downloadLogo} alt="download cv" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
